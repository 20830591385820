export default {
  root: (show) => ({
    opacity: show ? 1 : 0,
    display: show ? "block" : "none",
    visibility: show ? "visible" : "hidden",
    maxHeight: show ? "100vh" : 0,
    height: show ? "100vh" : 0,
    transition: "all 0.3s ease-out",
    flexDirection: "column",
    width: "100%",
    overflow: "auto",
    backgroundColor: "#ebedf0",
    color: "#000",
  }),
  rootGrid: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    gap: 2,
    backgroundColor: "#fff",
    paddingBottom: 2,
  },
  rootItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    justifyContent: "space-between",
    gap: 1.5,
    paddingLeft: 1,
    paddingRight: 1,
    minHeight: 80,
  },
  rootHeader: {
    padding: 2.5,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    color: "#000",
  },
  headerButton: { textTransform: "none" },
  containerItem: {
    display: "flex",
    flexDirection: "column",
  },
  rootBottom: {
    marginTop: 2,
    paddingLeft: 0.5,
    backgroundColor: "#fff",
  },
  bottomComment: { marginTop: 1, marginLeft: 2.5, color: "#74787a" },
  containerGood: { flexGrow: 1, display: "flex", flexDirection: "column" },
  goodPrice: { fontWeight: "bold" },
};
