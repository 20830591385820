export default {
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    overflow: "hidden",
  },
};
